import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startloadProductos } from '../../../app/store/catalagos/thunks';
import { SearchProd } from './panels/SearchProd';
import { Minus, Plus, Save, Search, Slash, Trash2 } from 'react-feather';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { SearchCliente } from '../orderNew/modals/SearchCliente';
import { searchClientes } from '../../../app/store/clientes/thunks';
import { startRegistroDetailInd, startVentaProductos } from '../../../app/store/ordenes/thunks';
import { NewCliente } from '../orderNew/modals/NewCliente';
import { AddFacturacion } from '../customers/modals/AddFacturacion';
import { SearchFacturacion } from '../orderClose/modals/SearchFacturacion';
import { SaleSpecial } from './panels/SaleSpecial';

export const Sales = () => {
  const dispatch = useDispatch();
  const { descuentos, tipos_pagos, servicios, productosAlm, isLoading } = useSelector(state => state.catalogos);
  const { facturacion, facturacion_edit } = useSelector(state => state.facturacion);
  const { usuario, id_emp } = useSelector(state => state.usuario);
  const { cliente_edit, status } = useSelector(state => state.clientes);
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      // toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });


  const { register: registerCC, trigger: triggerCC, setFocus: setFocusCC, getValues: getValuesCC, setValue: setValueCC, formState: { errors: errorsCC }, handleSubmit: handleSubmitCC, reset: resetCC } = useForm(
    {
      mode: 'all',
      defaultValues: {
        // enciende: 0, cargador_bat: 0, sonido: 0, red: 0, ensamblado: 0, teclado: 0, limpieza: 0, retorno: 0, checklist: 0, solo_rev: 0
      }
    });



  useEffect(() => {
    dispatch(startloadProductos());
  }, []);

  // Buscador
  const { register, reset, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      textSearch: "",
      param: "1"
    }
  });

  const handledSearch = (data) => {
    dispatch(searchClientes(data))
  }

  const [ProductosCompra, setProductosCompra] = useState([]);
  // const [ProductosCompra, setProductosCompra] = useState([]);


  const [Pagos, setPagos] = useState([]);

  const handledAddProduct = (e) => {

    let producto = ProductosCompra.filter(item => item.ID == e);
    if (producto.length === 0) {


      const result = productosAlm.filter(item => item.ID == e)[0];
      let servicioProd = ProductosCompra.filter(item => item.producto_id == result.ID)
      // console.log(result);
      if (servicioProd == 0) {
        Toast.fire({
          icon: 'success',
          title: 'Producto Agregado a la lista'
        });

        let sol = {
          "ID": result.ID,
          "NOMBRE": result.NOMBRE,
          "DESCRIPCION": result.DESCRIPCION,
          "COSTO_PUBLICO": result.COSTO_PUBLICO,
          "EXISTENCIA": result.STOCK,
          "CANTIDAD": 1,
          "ACUMULADO": 1 * Number(result.COSTO_PUBLICO),
          "VENTA_ESPECIAL": 0,

        }
        setProductosCompra([...ProductosCompra, sol]);

      } else {
        Toast.fire({
          icon: 'error',
          title: 'Ese producto ya fue agregado al listado'
        });
      }
    }
  }

  const handleRemoveProducto = (e) => {
    let arrayANTERIOR = [...ProductosCompra];
    let result = arrayANTERIOR.filter(item => item.ID != e);
    setProductosCompra(result);
  }

  // console.log(ProductosCompra);

  const changeValue = (e, i) => {
    let add = Number(e.target.value);

    console.log(add);

    let nuewArray = ProductosCompra.map(item => {
      if (item.ID === i) {

        if (add >= Number(item.EXISTENCIA)) {
          Toast.fire({
            icon: 'error',
            title: 'No es posible agregar mas cantidad de ese producto'
          });
          let ac = Number(item.EXISTENCIA) * Number(item.COSTO_PUBLICO);

          document.getElementById("value_exist_max").value = item.EXISTENCIA
          return { ...item, CANTIDAD: item.EXISTENCIA, ACUMULADO: ac };
        } else {
          let ac = Number(add) * Number(item.COSTO_PUBLICO)
          return { ...item, CANTIDAD: add, ACUMULADO: ac };
        }
      }
      return item;
    });

    setProductosCompra(nuewArray);
    // console.log(nuewArray);
  }

  useEffect(() => {
    let acumulado = 0; let IVAT = 0; let total = 0; let subtotal = 0;
    ProductosCompra.forEach(item => {
      acumulado = acumulado + item.ACUMULADO;
    });

    // IVAT = subtotal * 0.16; 
    // total = subtotal + IVAT;


    // IVAT = ((subtotal / 1.16)).toFixed(2); 
    // total = Number(subtotal) + Number(IVAT);


    subtotal = (Number(acumulado) / 1.16).toFixed(2);
    IVAT = (Number(subtotal) * 0.16);

    total = Number(subtotal) + Number(IVAT);

    setValueCC('subtotal', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal));
    setValueCC('iva', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(IVAT));
    setValueCC('total', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total));

  }, [ProductosCompra])



  // Rutina MUltiples pagos
  const changeCambio = (g) => {

    // console.log(g);


    let total = document.getElementById('total_orden').value.replace(/[$,]/g, '');
    let iva = document.getElementById('iva_orden').value.replace(/[$,]/g, '');
    let subtotal = document.getElementById('subotal_orden').value.replace(/[$,]/g, '');
    let formaPago = document.getElementById('formaPago_orden').value;
    let pago = g.target.value;
    // subtotal_x = acumuladoServicios + acumuladoRefacciones + AcumuladocostoAlmacenaje; IVA = subtotal_x * 0.16; total = subtotal_x + IVA;
    if (Pagos.length != 0) {
    } else {
      let cambio = pago - total;
      setValueCC('cambio', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cambio));
    }

  }

  const cancel = () => {
    console.log('Cancelar');

  }

  const addFormaPago = () => {
    let pago = document.getElementById('pago_orden').value;
    let formaPago = document.getElementById('formaPago_orden').value;
    let text = document.getElementById('formaPago_orden');
    let selectedIndex = text.selectedIndex;
    let selectedText = text.options[selectedIndex].text;
    let comentarios = document.getElementById('comentarios_orden').value;
    let item = {
      "forma_pago": formaPago,
      "string": selectedText,
      "comentarios": comentarios,
      "total": pago
    }
    setPagos([...Pagos, item]);

  }

  const handleRemovePago = (e) => {
    let arrayANTERIOR = [...Pagos];
    arrayANTERIOR.splice(e, 1);
    setPagos([...arrayANTERIOR]);
  }

  useEffect(() => {
    let totalpago = 0;
    let total = document.getElementById('total_orden').value.replace(/[$,]/g, '');
    Pagos.forEach(item => {
      totalpago = totalpago + Number(item.total);
    });

    let cambio = totalpago - total;
    setValueCC('cambio', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cambio));
  }, [Pagos]);


  const closeOrden = () => {

    // console.log('alive');
    let idCliente = 1;
    if (cliente_edit.length != 0) {
      idCliente = cliente_edit.ID;
    }

    let idFacturacion = getValuesCC('id_facturacion');

    let div = document.getElementById("facturacion_div");

    let flagFacturacion = 0;

    if (div && div.style.display === 'block') {
      // console.log('Dentro IF');
      // console.log(getValuesCC('id_facturacion'));
      if (getValuesCC('id_facturacion') == "" || getValuesCC('id_facturacion') == undefined) {
        // console.log('Dentro IF id facturacion');
        flagFacturacion = 1;
      }
    }

    let total = document.getElementById('total_orden').value.replace(/[$,]/g, '');
    let iva = document.getElementById('iva_orden').value.replace(/[$,]/g, '');
    let subtotal = document.getElementById('subotal_orden').value.replace(/[$,]/g, '');
    let formaPago = document.getElementById('formaPago_orden').value;
    let comentarios = document.getElementById('comentarios_orden').value;
    let pago = document.getElementById('pago_orden').value;

    if (Pagos.length != 0) {

      let acumulador = 0;

      Pagos.forEach(item => {
        acumulador = acumulador + Number(item.total);
      });

      if (acumulador < total) {
        Toast.fire({
          icon: 'error',
          title: 'Debe realizar el cobro correspondiente para la orden'
        });
        return;
      } else {

        if (flagFacturacion == 1) {

          idFacturacion = 0;
          Swal.fire({
            title: 'Atención',
            text: `Está por realizar una venta, indico que se requiere factura, No se han indicado sus datos. (Puede ir al menu Facturacion, seccion pendientes a indicarlos, ya que se haya realizado la venta)
             ¿Desea de continuar con esta acción?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(startVentaProductos(usuario, idFacturacion, ProductosCompra, idCliente, total, iva, subtotal, formaPago, comentarios, pago, Pagos))
            } else {
              return;
            }
          })
        } else {
          Swal.fire({
            title: 'Atención',
            text: `Está por realizar una venta, ¿Desea de continuar con esta acción?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(startVentaProductos(usuario, idFacturacion, ProductosCompra, idCliente, total, iva, subtotal, formaPago, comentarios, pago, Pagos))
            } else {
              return;
            }
          })
        }
      }
      // 
    } else {
      console.log('Normal');
      console.log(total);
      console.log(pago);
      if (pago < total) {
        Toast.fire({
          icon: 'error',
          title: 'Debe realizar el cobro correspondiente para la orden'
        });
        return;
      } else {
        // console.log('DISPATCH');


        if (flagFacturacion == 1) {

          idFacturacion = 0;
          Swal.fire({
            title: 'Atención',
            text: `Está por realizar una venta, indico que se requiere factura, No se han indicado sus datos. (Puede ir al menu Facturacion, seccion pendientes a indicarlos, ya que se haya realizado la venta)
             ¿Desea de continuar con esta acción?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(startVentaProductos(usuario, idFacturacion, ProductosCompra, idCliente, total, iva, subtotal, formaPago, comentarios, pago, Pagos));
            } else {
              return;
            }
          })
        } else {
          Swal.fire({
            title: 'Atención',
            text: `Está por realizar una venta, ¿Desea de continuar con esta acción?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(startVentaProductos(usuario, idFacturacion, ProductosCompra, idCliente, total, iva, subtotal, formaPago, comentarios, pago, Pagos));
            } else {
              return;
            }
          })
        }
      }


    }
  }

  const showDivFacturacion = (e) => {
    document.getElementById("facturacion_div").style.display = "block";
  }

  const HideDivFacturacion = (e) => {
    document.getElementById("facturacion_div").style.display = "none";
    setValueCC('id_facturacion', "");
    setValueCC('rfc_factruracion', "");
    setValueCC('razon_soc_facturacion', "");
    setValueCC('regimen_facturacion', "");
    setValueCC('telefono_facturacion', "");
    setValueCC('correo_facturacion', "");
    setValueCC('direccion_facturacion', "");
    setValueCC('cp_facturacion', "");
  }

  useEffect(() => {
    if (facturacion_edit.length != 0) {
      setValueCC('id_facturacion', facturacion_edit.ID);
      setValueCC('rfc_factruracion', facturacion_edit.RFC);
      setValueCC('razon_soc_facturacion', facturacion_edit.RAZON_SOCIAL);
      setValueCC('regimen_facturacion', facturacion_edit.REGIMEN_FISCAL);
      setValueCC('telefono_facturacion', facturacion_edit.TELEFONO);
      setValueCC('correo_facturacion', facturacion_edit.CORREO);
      setValueCC('direccion_facturacion', facturacion_edit.DIRECCION + ', CP:' + facturacion_edit.COD_POSTAL);
      setValueCC('cp_facturacion', facturacion_edit.COD_POSTAL);
    }

  }, [facturacion_edit]);


  const solo_num = (e) => {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
    ];

    const inputValue = e.target.value;
    const hasDecimal = inputValue.includes('.');

    // Si ya existe un punto decimal, solo permitir dos dígitos después de él
    if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
      e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
      return;
    }

    if (
      (e.key >= '0' && e.key <= '9') || // Números
      allowedKeys.includes(e.key) // Otras teclas permitidas
    ) {
      return true;
    } else {
      e.preventDefault(); // Evitar cualquier otra tecla
    }
  };



  return (
    <>
      <SearchCliente /> <NewCliente />
      <div className='row'>
        <h1 className="h4 mb-3 fw-bold">Venta productos</h1>

        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3'>
          <div className="card ">
            <div className="card-body">
              <form
                onSubmit={handleSubmit(handledSearch)}
              >
                <div className='mb-2'>
                  <label className="mb-1 text-dark">Buscar por:</label>
                  <div className="d-flex">
                    <select className="form-select w-50 me-1"
                    // {...register("param")}
                    >
                      <option value='1'>NOMB.</option>
                      <option value='0'>CVE.</option>

                      <option value='2'>TEL.</option>
                      <option value='3'>CEL.</option>
                    </select>
                    <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off'
                      {...register("textSearch", { required: 'Introduce termino a buscar' })}
                    />
                    <button className="btn btn-outline-secondary" type="submit" data-bs-toggle="modal" data-bs-target="#searchCustomer">
                      <Search size="20" />
                    </button>
                  </div>
                </div>
              </form>
              <a className="link " data-bs-toggle="modal" data-bs-target="#newCliente">Agregar Nuevo Cliente</a>
            </div>

            {
              status == 'CLIENTE_ENCONTRADO' ?
                <>
                  <AddFacturacion /> <SearchFacturacion />
                  <hr className="my-0" />
                  <div className="card-body">
                    <div className="row mb-1">
                      <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                        <h5 className="fw-bold me-2 border-0">Datos del Cliente:  </h5>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-end">
                      </div>
                    </div>
                    <hr className="my-0" />

                    <div className="row">
                      <div className="col-12 mt-3">
                        <div className="mb-1">
                          <label className="form-label">Nombre:</label>
                          <input type="text" className="form-control" disabled value={cliente_edit.NOMBRE} />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Telefono:</label>
                          <input type="text" className="form-control" disabled value={cliente_edit.TELEFONO} />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Celular:</label>
                          <input type="text" className="form-control" disabled value={cliente_edit.CELULAR} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label">Correo electronico:</label>
                          <input type="text" className="form-control" disabled value={cliente_edit.EMAIL} />
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        {
                          cliente_edit.TIPO_CLIENTE == 1 ?
                            <>
                              <div className="fw-bold align-middle">
                                <span > Nivel: {cliente_edit.CLAS_CLIENTE} </span>
                              </div>
                            </> : <div className="fw-bold align-middle">
                              <span > Nivel: {cliente_edit.CLAS_CLIENTE} </span>
                            </div>
                        }
                      </div>
                      <div className="col-12 mt-2">

                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="requierofacura" id="inlineRadio1" value="0" defaultChecked onClick={e => HideDivFacturacion(e)} />
                          <label className="form-check-label" >No, requiero factura</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="requierofacura" id="inlineRadio2" value="1" onClick={e => showDivFacturacion(e)} />
                          <label className="form-check-label" >Si, requiero factura</label>
                        </div>
                      </div>

                      <div id="facturacion_div" className="col-12 mt-4" style={{ display: "none" }}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <h5 className="fw-bold  border-0">Datos de facturación </h5>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-start mb-1">
                          <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#newFacturacion">
                            <Plus size='20' />
                          </button>
                          <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#searchFacturacion">
                            <Search size='20' />
                          </button>
                        </div>
                        <hr className="my-0" />


                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl123 col-xxl-12 mt-2">

                          <input type="hidden" className="form-control"
                            disabled  {...registerCC("id_facturacion")}
                          />
                          <div className="mb-1">
                            <label className="form-label">RFC:</label>
                            <input type="text" className="form-control"
                              disabled {...registerCC("rfc_factruracion")}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

                          <div className="mb-1">
                            <label className="form-label">Razon Social:</label>
                            <input type="text" className="form-control"
                              {...registerCC("razon_soc_facturacion")} disabled
                            />
                          </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="mb-1">
                            <label className="form-label">Regimen:</label>
                            <input type="text" className="form-control"
                              {...registerCC("regimen_facturacion")} disabled
                            />
                          </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="mb-1">
                            <label className="form-label">Telefono:</label>
                            <input type="text" className="form-control"
                              {...registerCC("telefono_facturacion")} disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="mb-1">
                            <label className="form-label">Correo Electronico:</label>
                            <input type="text" className="form-control"
                              {...registerCC("correo_facturacion")} disabled
                            />
                          </div>
                        </div>


                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="mb-2">
                            <label className="form-label">Dirección:</label>
                            <textarea className="form-control" rows="4"
                              {...registerCC("direccion_facturacion")} disabled
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                          <div className="mb-1">
                            <label className="form-label">CP:</label>
                            <input type="text" className="form-control"
                              {...registerCC("cp_facturacion")} disabled
                            />
                          </div>
                        </div>

                      </div>


                    </div>
                  </div>
                </> : <></>
            }
          </div>
        </div>

        <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9'>

          <div className="card">

            <div className="card-body">

              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Catalogo</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Venta Especial</button>
                </li>

              </ul>
              <div className="tab-content border-top" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" >
                  <SearchProd handledAddProduct={handledAddProduct} />
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" >

                  <SaleSpecial setProductosCompra={setProductosCompra} ProductosCompra={ProductosCompra} />
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h5 className="card-title text-black mb-0">Agregados para la compra</h5>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th scope="col" className='text-center'>ACCIONES</th>
                      <th scope="col">SKU</th>
                      <th scope="col">NOMBRE</th>
                      <th scope="col">DESCRIPCION</th>
                      <th scope="col">COSTO</th>
                      <th scope="col" className='text-start'>CANTIDAD</th>
                      <th scope="col">ACUMULADO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Iterar array y calculos */}

                    {ProductosCompra.length === 0 ?
                      <>
                        <tr>
                          <td colSpan={6}>No se han agregado productos a comprar</td>
                        </tr>
                      </> :
                      ProductosCompra.map((item, index) => (
                        <tr key={index}>
                          <td scope="row" className='text-center'>
                            <button type="button" className="btn btn-outline-danger" onClick={() => handleRemoveProducto(item.ID)}><Trash2 size='20' /></button>
                          </td>
                          <td scope="row">{item.ID}</td>
                          <td>{item.NOMBRE}</td>
                          <td>{item.DESCRIPCION}</td>
                          <td>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_PUBLICO)}</td>
                          <td className='text-end' >
                            <input type="number" className="form-control " id='value_exist_max' style={{ width: '120px' }} min={1} onKeyDown={solo_num} onChange={e => changeValue(e, item.ID)} defaultValue={item.CANTIDAD} />
                          </td>
                          <td>
                            <input type="text" className="form-control text-end" disabled style={{ width: '120px' }} min={0} value={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.ACUMULADO)}
                            />
                          </td>
                        </tr>
                      ))
                    }


                    {/* No iterar */}
                    <tr>
                      <td colSpan="6" className="text-end fw-bold border-0" >
                        SUBTOTAL:
                      </td>
                      <td className="border-0">
                        <input type="text" id='subotal_orden' className="form-control text-end" style={{ width: '120px' }} disabled {...registerCC("subtotal")} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6" className="text-end fw-bold border-0">
                        IVA:
                      </td>
                      <td className="border-0">
                        <input type="text" id='iva_orden' className="form-control text-end" style={{ width: '120px' }} disabled {...registerCC("iva")} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6" className="text-end fw-bold border-0">
                        TOTAL A COBRAR:
                      </td>
                      <td className="border-0">
                        <input type="text" id='total_orden' className="form-control text-end" style={{ width: '120px' }} disabled {...registerCC("total")} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="6" className="text-end fw-bold border-0">
                        {/* FORMA PAGO: */}
                        CAMBIO
                      </td>
                      <td className="border-0">
                        <input type="text" className="form-control text-end" id='cambio_orden' disabled style={{ width: '120px' }}
                          {...registerCC("cambio")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr className="my-0" />

              <div className='card-body'>
                <div className='row'>

                  <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                    <label className="mb-1 fw-bold text-dark">Forma de Pago</label>
                    <select className="form-select " id='formaPago_orden'  >
                      {
                        tipos_pagos.map((item, index) => (
                          item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                            : <option key={index} style={{ display: "none" }} ></option>
                        ))
                      }
                    </select>
                  </div>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                    <label className="mb-1 fw-bold text-dark">Comentarios</label>
                    <input type="text" className="form-control text-start" defaultValue={'NA'} id='comentarios_orden' />
                  </div>

                  <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    <label className="mb-1 fw-bold text-dark">Pago</label>
                    <input type="number" className="form-control text-end" id='pago_orden' onKeyDown={solo_num} min={1}
                      onChange={g => changeCambio(g)}
                    // style={{ width: '120px' }}
                    />
                  </div>
                  <div className='col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1'>
                    <button type="button" className="btn btn-outline-primary mt-4" onClick={addFormaPago}><Plus size='20' /></button>
                  </div>




                </div>

                <div className='row'>
                  <div className='table-responsive'>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">ACCIONES</th>
                          <th scope="col">FORMA DE PAGO</th>
                          <th scope="col">COMENTARIOS</th>
                          <th scope="col">PAGO</th>
                        </tr>
                      </thead>
                      <tbody>

                        {Pagos.length === 0 ?
                          <>
                            <tr>
                              <td colSpan={4}>No se han agregado mas de una forma de pago</td>
                            </tr>
                          </> :
                          Pagos.map((item, index) => (
                            <tr key={index} >
                              <th scope="row">
                                <button type="button" className="btn btn-outline-danger" onClick={() => handleRemovePago(index)}
                                //
                                ><Trash2 size='20' /></button>
                              </th>
                              <td>{item.string}</td>
                              <td>{item.comentarios}</td>
                              <td>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.total)}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>



              <div className="text-end">
                <button type="button" className="btn btn-outline-danger mt-2"
                  onClick={cancel}
                >
                  <Slash size='20' /> Cancelar
                </button>
                <button type="submit" className="btn btn-outline-primary mt-2 ms-3" onClick={closeOrden}>
                  <Save size='20' /> Guardar
                </button>
              </div>
            </div>




          </div>
        </div>
      </div >



      {/* <div className="container row mt-2">
        {/* <h5 className="fw-bold me-2 border-0">Resumen de la Cuenta </h5> *
       
      </div> */}
    </>
  )
}
