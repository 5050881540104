import React, { useEffect } from 'react'
import { SumaryOrders } from './panels/SumaryOrders'


export const Report = () => {

  
  return (
    <>
    <SumaryOrders/>
    </>
  )
}
