import React, { useEffect, useState } from 'react';
// import { NewCliente } from './modals/NewCliente';
import { NewEquipo } from './modals/NewEquipo';
import { AlertTriangle, Plus, RefreshCcw, Save, Search, Slash, Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { clearStoreSearch, searchClientes } from '../../../app/store/clientes/thunks';
import { SearchCliente } from './modals/SearchCliente';
import { asignedEquipo, clearStoreEquiposCliente, startloadEquipos, startloadEquiposOrden } from '../../../app/store/equipos/thunks';
import { startRegistroOrden } from '../../../app/store/ordenes/thunks';
import { NewCustomer } from '../customers/modals/NewCustomer';
import { useNavigate } from 'react-router-dom';

export const NewOrder = () => {

  const dispatch = useDispatch();
  const cancel = () => {
    window.location.reload();
  }

  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      // toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const { servicios } = useSelector(state => state.catalogos);
  const { cliente_edit, status } = useSelector(state => state.clientes);
  const { equipos, equipo_edit, status_equipo } = useSelector(state => state.equipos);
  const { status_ordenes } = useSelector(state => state.ordenes);
  const { usuario, id_emp } = useSelector(state => state.usuario);
  const [Servicios, setServicios] = useState([]);
  const [Fotografias, setFotografias] = useState([]);

  // Control de Servicios
  const addServicio = () => {
    let id_servicio = document.getElementById("servicio_realizar").value;

    if (id_servicio != '') {
      let servicio = servicios.filter(item => item.ID === id_servicio)[0];
      let servicioExist = Servicios.filter(item => item.ID == id_servicio)

      if (servicioExist == 0) {
        Toast.fire({
          icon: 'success',
          title: 'Servicio Agregado de la lista'
        });
        // console.log(servicio);

        let PROD = {
          "ID": servicio.ID,
          "NOMBRE": servicio.NOMBRE,
          "DESCRIPCION": servicio.DESCRIPCION,
          "HR_REALIZAR": servicio.HR_REALIZAR,
          "COSTO_SERV": servicio.COSTO_SERV,
          "GARANTIA": servicio.GARANTIA,
          "ESTADO": servicio.ESTADO,
          "RECEPCION": 1,
          "PROD_ID": servicio.PRODUCTO_ID,
          "CANT_PROD": servicio.CANTIDAD_PROD
        }

        // console.log(PROD);

        setServicios([...Servicios, PROD]);
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Ese servicio ya fue agregado'
        });
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Debe seleccionar a un servicio de la listas'
      });
    }
  }

  const removeServiciO = (id) => {
    Toast.fire({
      icon: 'success',
      title: 'Servicio Eliminado de la lista'
    });
    let arrayANTERIOR = [...Servicios];
    // console.log(arrayANTERIOR);
    let result = arrayANTERIOR.filter(item => item.ID != id);
    setServicios(result);
  }

  const removeAllServicio = () => {
    if (Servicios.length != 0) {
      setServicios([]);
      Toast.fire({
        icon: 'success',
        title: 'Se ha eliminado la lista de Servicios a realizar'
      });
    }
  }


  // Control de Fotografias
  const addFotografia = () => {
    // setEvidencias([]);
    let filesA = document.getElementById("fotografia_up").files;
    // let evidencia = Fotografias.filter(item => item.name == filesA[0].name);
    setFotografias([...Fotografias, ...filesA]);
    Toast.fire({
      icon: 'success',
      title: 'Fotografia del equipo agregada al Listado'
    })

    // console.log(filesA[0].name);
    // if (evidencia.length === 0) {
    //   Toast.fire({
    //     icon: 'success',
    //     title: 'Fotografia del equipo agregada al Listado'
    //   })
    // }
    // else {
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'Fotografia del Equipo repetida, favor de verificar fotografía'
    //   })
    // }
  }

  const removeFotografia = (name) => {
    Toast.fire({
      icon: 'success',
      title: 'Fotografia Eliminada de la lista'
    });
    let arrayANTERIOR = [...Fotografias];
    // console.log(arrayANTERIOR);
    let result = arrayANTERIOR.filter(item => item.name != name);
    setFotografias(result);
  }

  const removeAllFotografias = () => {
    if (Fotografias.length != 0) {
      setFotografias([]);
      Toast.fire({
        icon: 'success',
        title: 'Se ha eliminado la lista de fotografias'
      });
    }
  }

  // Buscador
  const { register, reset, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      textSearch: "",
      param: "1"
    }
  });

  const handledSearch = (data) => {
    // console.log(data);
    resetOrden({})
    setServicios([]); setFotografias([]);
    dispatch(clearStoreEquiposCliente())
    dispatch(searchClientes(data))
  }


  useEffect(() => {
    if (status == 'CLIENTE_ENCONTRADO') {
      dispatch(startloadEquiposOrden(cliente_edit.ID));
    }
  }, [status])

  const handleSelectedEquipo = (e) => {
    let id = e.target.value;

   
    setValueOrden('sisoperativo', '');
    setValueOrden('serieEquipo', '');

    if (id != '') {

      let data = equipos.filter(item => item.ID == id)[0];

      if (data.FOLIO_ORDEN_ACTIVA == 0) {
        setValueOrden('sisoperativo', data.SIS_OP);
        setValueOrden('serieEquipo', data.NO_SERIE);
      } else {
        setValueOrden('equipo_id', '');
        Swal.fire({
          icon: 'warning',
          title: 'Atencion',
          text: `El equipo seleccionado tiene una Orden de Serivicio Activa, por lo cual no puede ser seleccionada, ¿Desea ver los detalles de la Orden de Servicio?`,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: '#3b7ddd',
          cancelButtonColor: '#dc3545',
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/trackingOrder/${data.FOLIO_ORDEN_ACTIVA}`);
          }
        });
      }
    }
  }

  const { register: registerOrden, trigger: triggerOrden, setFocus: setFocusOrden, getValues: getValuesOrden, setValue: setValueOrden, formState: { errors: errorsOrden }, handleSubmit: handleSubmitOrden, reset: resetOrden } = useForm(
    {
      mode: 'all',
      defaultValues: {
        // orden_premium: 0,
        cliente_id: "",
        equipo_id: "",
        cargador_sino: "",
        cargador_serie: "",
        bateria_sino: "",
        bateria_serie: "",
        enciende_sino: "",
        contrasena: "",
        descripcion_falla: "",
      }
    });


  const onSaveOrden = (dat) => {
    // const Validation = await triggerOrden(['cargador_sino']);
    // console.log(Validation);
    // console.log(Servicios);
    // console.log(Fotografias);
    // console.log(data);

    let data = {};

    if (cliente_edit.TIPO_CLIENTE == 2) {

      data = {
        "bateria_serie": dat.bateria_serie,
        "bateria_sino": dat.bateria_sino,
        "cargador_serie": dat.cargador_serie,
        "cargador_sino": dat.cargador_sino,
        "contrasena": dat.contrasena,
        "descripcion_falla": dat.descripcion_falla,
        "enciende_sino": dat.enciende_sino,
        "equipo_id": dat.equipo_id,
        "orden_premium": true,
        "serieEquipo": dat.serieEquipo,
        "sisoperativo": dat.sisoperativo,
      }

    } else {
      data = {
        "bateria_serie": dat.bateria_serie,
        "bateria_sino": dat.bateria_sino,
        "cargador_serie": dat.cargador_serie,
        "cargador_sino": dat.cargador_sino,
        "contrasena": dat.contrasena,
        "descripcion_falla": dat.descripcion_falla,
        "enciende_sino": dat.enciende_sino,
        "equipo_id": dat.equipo_id,
        "orden_premium": dat.orden_premium,
        "serieEquipo": dat.serieEquipo,
        "sisoperativo": dat.sisoperativo,
      }
    }

    // console.log(data);

    if (Servicios.length == 0) {
      Toast.fire({
        icon: 'error',
        title: 'NO se han agregado Servicios'
      });
      return;
    }

    if (Fotografias.length == 0) {
      Toast.fire({
        icon: 'error',
        title: 'NO se han agregado Fotografias'
      });
      return;
    }

    // console.log(Servicios);

    dispatch(startRegistroOrden(id_emp, cliente_edit.ID, data, Servicios, Fotografias));
    resetOrden({}); setServicios([]); setFotografias([]);

  }

  const changeValueCargador = (e) => {
    setValueOrden('cargador_serie', 'NA')
  }
  const changeValueBateria = (e) => {
    setValueOrden('bateria_serie', 'NA')
  }


  return (
    <>
      <NewCustomer /> <NewEquipo /> <SearchCliente />
      <div className="mb-3">
        <h1 className="h4 d-inline align-middle fw-bold">Nueva Orden de Servicio</h1>
      </div>
      {
        status_ordenes == 'GUARDANDO_ORDEN' ?
          <>
            <div className='div_center'>
              <div className='text-center'>
                <div className="spinner"></div>
              </div>
              <div className='text-center text-left-15'>
                <span className='fw-bold'>Guardando</span>
              </div>
              <div className='text-center text-left-15'>
                <div className="spinner"></div>
              </div>
            </div>
          </> :
          <>  <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
              <div className="card ">
                <div className="card-body">
                  <form onSubmit={handleSubmit(handledSearch)}>
                    <div className='mb-2'>
                      <label className="mb-1 text-dark">Buscar por:</label>
                      <div className="d-flex">
                        <select className="form-select w-50 me-1" {...register("param")}>
                          <option value='0'>CVE.</option>
                          <option value='1'>NOMB.</option>
                          <option value='2'>TEL.</option>
                          <option value='3'>CEL.</option>
                        </select>
                        <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off' {...register("textSearch", { required: 'Introduce termino a buscar' })} />
                        <button className="btn btn-outline-secondary" type="submit" data-bs-toggle="modal" data-bs-target="#searchCustomer">
                          <Search size="20" />
                        </button>
                      </div>
                    </div>
                  </form>
                  <a className="link " data-bs-toggle="modal" data-bs-target="#newCliente">Agregar Nuevo Cliente</a>
                </div>


                {
                  status == 'CLIENTE_ENCONTRADO' ?
                    <>
                      <hr className="my-0" />
                      <div className="card-body">
                        <div className="row mb-1">
                          <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <h5 className="fw-bold me-2 border-0">Datos del Cliente: {cliente_edit.ID} </h5>
                          </div>
                          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-end">
                            {/* <button type="button" className="btn btn-link btn-sm border-0"
                  data-bs-toggle="modal" data-bs-target="#editCustomerModal">
                  <i className="align-middle" data-feather="edit"></i>
                </button> */}
                          </div>
                        </div>
                        <hr className="my-0" />

                        <div className="row">
                          <div className="col-12 mt-3">
                            <div className="mb-1">
                              <label className="form-label">Nombre:</label>
                              <input type="text" className="form-control" disabled value={cliente_edit.NOMBRE} />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-1">
                              <label className="form-label">Telefono:</label>
                              <input type="text" className="form-control" disabled value={cliente_edit.TELEFONO} />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-1">
                              <label className="form-label">Celular:</label>
                              <input type="text" className="form-control" disabled value={cliente_edit.CELULAR} />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="mb-3">
                              <label className="form-label">Correo electronico:</label>
                              <input type="text" className="form-control" disabled value={cliente_edit.EMAIL} />
                            </div>
                          </div>
                          <div className="col-12 text-center">
                            <span> Cliente: {cliente_edit.CLAS_CLIENTE} </span>
                            {/* <h5 className="fw-bold">Cliente: {cliente_edit.CLAS_CLIENTE} </h5> */}
                          </div>
                        </div>
                      </div>
                    </> : <></>
                }
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
              <div className="card">
                <div className="card-body">

                  {
                    status == 'CLIENTE_ENCONTRADO' ?
                      <>

                        <form onSubmit={handleSubmitOrden(onSaveOrden)}  >
                          <h5 className="card-title fw-bold text-dark mb-2">Detalles de la Orden de Servicio</h5>
                          <hr className="my-0" />

                          <div className="row mt-2">
                            <div className='col-12'>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" {...registerOrden("orden_premium")} />
                                <label className="form-check-label h5 fw-bold" >
                                  ORDEN PREMIUM
                                </label>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

                              <div className="mb-3">
                                <label className="form-label ">Equipo:</label>
                                <select className="form-select" {...registerOrden("equipo_id", { required: 'Seleccione equipo' })} onChange={e => handleSelectedEquipo(e)}>
                                  <option value="" >Seleccione un equipo</option>

                                  {
                                    equipos.length == 0 ? <option value="">No hay equipos asociados al cliente</option> :
                                      equipos.map((item, index) => (
                                        item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.CATEGORIA} {item.MARCA} {item.NO_SERIE}</option>
                                          : <option key={index} style={{ display: "none" }} ></option>
                                      ))
                                  }

                                </select>
                                {
                                  errorsOrden.equipo_id &&
                                  <div className='mb-3 mt-2'>
                                    <AlertTriangle size="20" color='#dc3545' />
                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                    <span className='fw-bold text-danger ms-1'><small>{errorsOrden.equipo_id.message}</small></span>
                                  </div>
                                }
                                <a className="link" data-bs-toggle="modal" data-bs-target="#newEquipo">Agregar Nuevo equipo</a>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="mb-3">
                                <label className="form-label">S.O:</label>
                                <input type="text" className="form-control" placeholder="" aria-label="First name"  {...registerOrden("sisoperativo")} disabled />
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="mb-3">
                                <label className="form-label">S.N:</label>
                                <input type="text" className="form-control" placeholder="" aria-label="First name" {...registerOrden("serieEquipo")} disabled />
                              </div>
                            </div>
                          </div>

                          <h5 className="card-title fw-bold text-dark mb-2">Detalles del equipo</h5>
                          <hr className="my-0" />

                          <div className="row mt-2">

                            {/* <!-- CARGADOR --> */}
                            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                              <label className="form-label fw-bold">Cargador:</label>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" {...registerOrden("cargador_sino", { required: 'Indica si recibe con cargador' })} value="1" />
                                <label className="form-check-label"> Si, lo recibo</label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="radio" {...registerOrden("cargador_sino", { required: 'Indica si recibe con cargador' })} value="0" onClick={e => changeValueCargador(e)} />
                                <label className="form-check-label" > No, lo recibo </label>
                              </div>
                              {
                                errorsOrden.cargador_sino &&
                                <div className='mb-3 mt-2'>
                                  <AlertTriangle size="20" color='#dc3545' />
                                  {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                  <span className='fw-bold text-danger ms-1'><small>{errorsOrden.cargador_sino.message}</small></span>
                                </div>
                              }

                              <div className="mb-3">
                                <label className="form-label">S.N:</label>
                                <input type="text" className="form-control" placeholder="No. de Serie" {...registerOrden("cargador_serie", { required: 'Indica no. de serie' })} />
                                {
                                  errorsOrden.cargador_serie &&
                                  <div className='mb-3 mt-2'>
                                    <AlertTriangle size="20" color='#dc3545' />
                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                    <span className='fw-bold text-danger ms-1'><small>{errorsOrden.cargador_serie.message}</small></span>
                                  </div>
                                }
                              </div>
                            </div>

                            {/* <!-- BATERIA --> */}
                            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                              <label className="form-label fw-bold">Bateria:</label>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" {...registerOrden("bateria_sino", { required: 'Indica si recibe con bateria' })} value="1" />
                                <label className="form-check-label" htmlFor="gridRadios1">  Si, lo recibo </label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="radio" {...registerOrden("bateria_sino", { required: 'Indica si recibe con bateria' })} value="0" onClick={e => changeValueBateria(e)} />
                                <label className="form-check-label" htmlFor="gridRadios2">  No, lo recibo </label>
                              </div>
                              {
                                errorsOrden.bateria_sino &&
                                <div className='mb-3 mt-2'>
                                  <AlertTriangle size="20" color='#dc3545' />
                                  {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                  <span className='fw-bold text-danger ms-1'><small>{errorsOrden.bateria_sino.message}</small></span>
                                </div>
                              }

                              <div className="mb-3">
                                <label className="form-label">S.N:</label>
                                <input type="text" className="form-control" placeholder="No. de Serie" {...registerOrden("bateria_serie", { required: 'Indica no. de serie' })} />
                                {
                                  errorsOrden.bateria_serie &&
                                  <div className='mb-3 mt-2'>
                                    <AlertTriangle size="20" color='#dc3545' />
                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                    <span className='fw-bold text-danger ms-1'><small>{errorsOrden.bateria_serie.message}</small></span>
                                  </div>
                                }
                              </div>
                            </div>

                            {/* <!-- ENCIENDE  --> */}
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                              <label className="form-label fw-bold">¿Enciende?:</label>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" {...registerOrden("enciende_sino", { required: 'Indica si enciende' })} value="1" />
                                <label className="form-check-label" htmlFor="gridRadios1"> Si, Enciende </label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="radio" {...registerOrden("enciende_sino", { required: 'Indica si enciende' })} value="0" />
                                <label className="form-check-label" htmlFor="gridRadios2">  No, Enciende  </label>
                              </div>
                              {
                                errorsOrden.enciende_sino &&
                                <div className='mb-3 mt-2'>
                                  <AlertTriangle size="20" color='#dc3545' />
                                  {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                  <span className='fw-bold text-danger ms-1'><small>{errorsOrden.enciende_sino.message}</small></span>
                                </div>
                              }
                              <div className="mb-3">
                                <label className="form-label">Contraseña:</label>
                                <input type="text" className="form-control" placeholder="Contraseña" defaultValue={'NA'}  {...registerOrden("contrasena", { required: 'Introduce la contraseña del equipo' })} />
                                {
                                  errorsOrden.contrasena &&
                                  <div className='mb-3 mt-2'>
                                    <AlertTriangle size="20" color='#dc3545' />
                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                    <span className='fw-bold text-danger ms-1'><small>{errorsOrden.contrasena.message}</small></span>
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="col-12">
                              <label className="form-label fw-bold">Descripción de la falla(s):</label>
                              <textarea className="form-control" rows="6" placeholder="Ingresa descripción (Maximo 490 caracteres)"
                                {...registerOrden('descripcion_falla', {
                                  required: 'La descripción de la falla(s) es requerido.',
                                  minLength: { value: 10, message: 'La descripcion de la falla debe contener por los menos 10 caracteres' },
                                  maxLength: { value: 490, message: 'La descripcion de la falla debe contener maximo 490 caracteres' }
                                })}></textarea>
                              {
                                errorsOrden.descripcion_falla &&
                                <div className='mb-3 mt-2'>
                                  <AlertTriangle size="20" color='#dc3545' />
                                  {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                  <span className='fw-bold text-danger ms-1'><small>{errorsOrden.descripcion_falla.message}</small></span>
                                </div>
                              }

                            </div>



                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                              <h5 className="card-title fw-bold text-dark mb-2">Servicios a realizar</h5>
                              <hr className="my-0 mb-3" />
                              <div className="d-flex">
                                <select className="form-select" id='servicio_realizar'>
                                  <option value="" >Seleccione un servicio</option>
                                  {
                                    servicios.map((item, index) => (
                                      item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE} - ${item.COSTO_SERV} </option>
                                        : <option key={index} style={{ display: "none" }} ></option>
                                    ))
                                  }
                                </select>

                                <button className="btn btn-outline-primary no border-0 " type="button" onClick={() => addServicio()} >
                                  <Plus size='20' />
                                </button>
                              </div>

                              <div className="mt-2 table-responsive table-scrollbar-5">
                                <table className="table table-sm">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">SERVICIO</th>
                                      <th scope="col" className="text-end">COSTO</th>
                                      <th scope="col" className="text-center">ACCIONES</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      Servicios.length === 0 ?
                                        <>
                                          <tr >
                                            <td scope="row" colSpan={4} className='text-danger fw-bold'> <AlertTriangle size={20} className='text-danger fw-bold me-2' /> No se han agregado servicios para llevar acabo en la Orden de Servicio</td>
                                          </tr>
                                        </> :
                                        Servicios.map((item, index) => (
                                          <tr key={index}>
                                            <td scope="row">{index + 1}</td>
                                            <td>{item.NOMBRE}</td>
                                            <td className="text-end">{item.COSTO_SERV}</td>
                                            <td className="text-center">
                                              <button className="btn btn-outline-danger border-0 " type="button" onClick={() => removeServiciO(item.ID)}>
                                                <Trash2 size='20' />
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                    }
                                  </tbody>
                                </table>

                              </div>
                              <button type="button" className="btn btn-outline-danger mt-3 mb-4" onClick={removeAllServicio} >Eliminar todos: ({Servicios.length})</button>
                            </div>

                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                              <h5 className="card-title fw-bold text-dark mb-2">Fotografias del equipo</h5>
                              <hr className="my-0 mb-3" />
                              <div className="d-flex">
                                <label htmlFor="formFile" className="form-label"></label>
                                <input className="form-control form-control" id="fotografia_up" type="file" accept="image/*" />
                                <button className="btn btn-outline-primary no border-0 " type="button" onClick={() => addFotografia()}>
                                  <Plus size='20' />
                                </button>
                              </div>

                              <div className="mt-2 table-responsive table-scrollbar-5">
                                <table className="table table-sm">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Archivo</th>
                                      <th scope="col" className="text-center">ACCIONES</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {
                                      Fotografias.length === 0 ?
                                        <>
                                          <tr >
                                            <td scope="row" colSpan={3} className='text-danger fw-bold'> <AlertTriangle size={20} className='text-danger fw-bold me-2' /> No se han agregado fotografias, de como se ha recibido el equipo</td>
                                          </tr>
                                        </> :
                                        Fotografias.map((item, index) => (
                                          <tr key={index}>
                                            <td scope="row">{index + 1}</td>
                                            <td>{item.name}</td>
                                            {/* <td className="text-end">{item.COSTO_SERV}</td> */}
                                            <td className="text-center">
                                              <button className="btn btn-outline-danger border-0 " type="button" onClick={() => removeFotografia(item.name)}>
                                                <Trash2 size='20' />
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                              <button type="button" className="btn btn-outline-danger mt-3 mb-4" onClick={removeAllFotografias} >Eliminar todos: ({Fotografias.length})</button>
                            </div>
                          </div>

                          <hr className="my-0" />
                          <div className="card-body text-end">
                            <button type="button" className="btn btn-outline-danger mt-2" onClick={cancel}>
                              <Slash size='20' /> Cancelar
                            </button>
                            <button type="submit" className="btn btn-outline-primary mt-2 ms-3 ">
                              <Save size='20' /> Guardar
                            </button>
                          </div>
                        </form>
                      </> : <>

                        <div className="alert alert-light" role="alert">
                          <h6 className="fw-bold text-dark h3">Instrucciones para Registrar una Nueva Orden de Servicio</h6>
                          <p className="card-text h4 justify">No es posible registrar una Orden de Servicio hasta que selecciones al cliente que le pertenecera, sigue los siguientes pasos...</p>
                          <p className="card-text mt-2"><span className='fw-bold'>Paso #1:</span><br /> Introduce el Nombre, Telefono, Celular, cualquiera de las opciones anteriores en el formulario de la izquierda/Arriba.</p>
                          <p className="card-text"><span className='fw-bold'>Paso #2:</span><br /> En el Modal que se abrira da clic al boton Azul Escoger y listo podras capturar una Orden de Servicio</p>
                          <p className="card-text"><span className='fw-bold'>Por la dudas:</span><br />
                            Si el cliente no esta registrado lo puedes dar de alta dando clic al enlace Agregar Nuevo Cliente, esta opcion esta en el formulario de la Izquierda/Arriba. O si lo deseas en el menu Clientes que se encuentra en el Sidebar.
                          </p>
                          {/* <p className="card-text"></p> */}
                        </div>
                      </>
                  }
                </div>
              </div>
            </div>
          </div></>
      }
    </>
  )
}

