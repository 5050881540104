import { Home, Archive, Sliders, Settings, Columns, CreditCard, Grid, User, Users, DollarSign, Package, FilePlus, FileText, FileMinus, Box, Codesandbox, Monitor, Activity, BarChart2 } from 'react-feather';

//Sidebar Items


export const ordenes = [
    {
        id: 0,
        title: "Dashboards",
        path: "/dashboard",
        indicator: "",
        icon: <Home size="20" />,
    },
    {
        id: 1,
        title: "Nueva Orden",
        path: "/newOrder",
        indicator: "",
        icon: <FilePlus size="20" />
    },
    {
        id: 2,
        title: "Seg. a Orden",
        path: "/trackingOrder",
        indicator: "",
        icon: <FileText size="20" />
    },
    {
        id: 3,
        title: "Ent. de Orden",
        path: "/closeOrder",
        indicator: "",
        icon: <FileMinus size="20" />
    },
    {
        id: 4,
        title: "Refacciones",
        path: "/parts",
        indicator: "",
        icon: <Box size="20" />
    },
   
];

export const clientes = [
    {
        id: 0,
        title: "Clientes",
        path: "/customers",
        indicator: "",
        icon: <CreditCard size="20" />,
    },
    {
        id: 1,
        title: "Venta",
        path: "/sales",
        indicator: "",
        icon: <Monitor size="20" />,
    },
    {
        id: 2,
        title: "Venta por pedido",
        path: "/salesOrder",
        indicator: "",
        icon: <Monitor size="20" />,
    },
];


export const operativos = [
    {
        id: 0,
        title: "Empleados",
        path: "/employees",
        indicator: "",
        icon: <Users size="20" />,
        show: 'Administrador',
    },
    {
        id: 1,
        title: "Inventario",
        path: "/warehouse",
        indicator: "",
        icon: <Package size="20" />,
    },
    {
        id: 2,
        title: "Finanzas",
        path: "/finance",
        indicator: "",
        icon: <DollarSign size="20" />,
    },
    {
        id: 3,
        title: "Facturación",
        path: "/invoicing",
        indicator: "",
        icon: <Activity size="20" />,
    },
    {
        id: 4,
        title: "Reportes",
        path: "/reports",
        indicator: <span className="badge bg-primary">1</span>,
        icon: <BarChart2 size="20" />,
    },


];

export const profile = [
    {
        id: 0,
        title: "Mi perfil",
        path: "/profile",
        indicator: "",
        icon: <User size="20" />
    }
]


export const administracion = [

    {
        id: 0,
        title: "Configuración",
        path: "/settings",
        indicator: "",
        icon: <Sliders size="20" />,

    },

];