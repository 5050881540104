import React, { useEffect, useRef } from 'react';
import { AlertTriangle, Save, Slash } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startRegistroCliente } from '../../../../app/store/clientes/thunks';

export const NewCliente = () => {
    const dispatch = useDispatch();
    const { tiposClientes, como_conocieron } = useSelector(state => state.catalogos);
    const { status, isLoading } = useSelector(state => state.clientes);
    const { usuario } = useSelector(state => state.usuario);
    const closeModal = useRef(null);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            nombreCliente: "",
            telefonoCliente: "",
            celularCliente: "",
            emailCliente: "",
            tipoCliente: ""
        }
    });

    const clearForm = () => {
        reset();
    }

    const handledSaveCliente = (data) => {
        // console.log(data);
        dispatch(startRegistroCliente(data, usuario));
        clearForm();
    }

    // useEffect(() => {
    //     if (status == 'CLIENTES_CARGADOS') {
    //         reset();
    //         // window.location.reload();
    //     }
    // }, [status])


    return (
        <div className="modal fade" id="newCliente" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Nuevo Cliente</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>
                    <form onSubmit={handleSubmit(handledSaveCliente)}>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("nombreCliente", { required: 'Nombre del cliente es requerido.' })} />
                                        {
                                            errors.nombreCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Telefono:</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' {...register("telefonoCliente", {
                                            required: 'Telefono del cliente es requerido.',
                                            minLength: { value: 10, message: 'Telefono debe terner minimo 10 digitos' },
                                            maxLength: { value: 10, message: 'Telefono debe terner maximo 10 digitos' }
                                        })} />
                                        {
                                            errors.telefonoCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.telefonoCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Celular:</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' {...register("celularCliente",
                                            {
                                                required: 'Celular del cliente es requerido.',
                                                minLength: { value: 10, message: 'Celular debe terner minimo 10 digitos' },
                                                maxLength: { value: 10, message: 'Celular debe terner maximo 10 digitos' }
                                            }
                                        )} />
                                        {
                                            errors.celularCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.celularCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Correo electronico:</label>
                                        <input type="email" className="form-control" aria-label="First name" autoComplete='off' {...register("emailCliente",
                                            {
                                                required: 'Correo del cliente es requerido.',
                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: 'Introduce una direccion de correo',
                                                },
                                            })} />
                                        {
                                            errors.emailCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.emailCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <label className="form-label ">Clasificacion de Cliente:</label>
                                    <select className="form-select" {...register('tipoCliente', { required: 'Debe seleccionar la Clasificacion de Cliente' })}>
                                        <option value="">Seleccione una clasificacion</option>
                                        {
                                            tiposClientes.map((item, index) => (
                                                item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                    : <option key={index} style={{ display: "none" }} ></option>
                                            ))
                                        }
                                    </select>
                                    {
                                        errors.tipoCliente &&
                                        <div className='text-left-5 mt-2'>
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.tipoCliente.message}</small></span>
                                        </div>
                                    }
                                </div>

                                <div className="col-6">
                                    <label className="form-label ">¿Como nos conocio?:</label>
                                    <select className="form-select" {...register('comoConocio', { required: 'Debe seleccionar como nos conocio el Cliente' })}>
                                        <option value="">Seleccione una clasificacion</option>
                                        {
                                            como_conocieron.map((item, index) => (
                                                item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.DESCRIPCION}</option>
                                                    : <option key={index} style={{ display: "none" }} ></option>
                                            ))
                                        }
                                    </select>
                                    {
                                        errors.comoConocio &&
                                        <div className='text-left-5 mt-2'>
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.comoConocio.message}</small></span>
                                        </div>
                                    }
                                </div>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size='20' /> Cancelar
                            </button>
                            <button type="submit" className="btn btn-outline-primary" >
                                <Save size="20" /> Guardar
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}
